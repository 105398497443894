import {createContext, useContext, useEffect, useState} from "react";

interface GlobalContextInterface {
    screenWidth: Number;
    setScreenWidth: Function;
    screenHeight: Number;
    setScreenHeight: Function;
    isMobile: Boolean;
    setIsMobile: Function;
    screenRatio: Number;
    setScreenRatio: Function;
}

const GlobalContext = createContext<GlobalContextInterface | null>(null);
export const useGlobalContext = () => {

    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error('useGlobalContext must be used within a GlobalContextProvider');
    }
    return context;

}

export const GlobalContextProvider = (props: any) => {
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
    const [screenRatio, setScreenRatio] = useState<number>(screenWidth / screenHeight);
    const [isMobile, setIsMobile] = useState<boolean>(screenWidth < screenHeight);


    useEffect(() => {

        setScreenHeight(window.innerHeight);
        setScreenWidth(window.innerWidth);
        setScreenRatio(screenWidth / screenHeight);
        setIsMobile(screenWidth < screenHeight);

    }, [window.innerWidth, window.innerHeight]);


    return (
        <GlobalContext.Provider
            value={{
                screenWidth,
                setScreenWidth,
                screenHeight, setScreenHeight, isMobile, setIsMobile, screenRatio, setScreenRatio
            }}>
            {props.children}
        </GlobalContext.Provider>
    )
}


