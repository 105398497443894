import React, {useRef, useState} from "react";
import {Button, Col, Nav, Navbar, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Arrow from "../assets/components/side_arrow.png";
import ProjectColors from "../Colors";
import {useGlobalContext} from "../context/GlobalState";

export type SidebarProps = {
    activeTab: string;
};

export default function Sidebar(props: SidebarProps) {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(true);
    // const [activeTab, setActiveTab] = useState(props.activeTab);
    const [firstDropdown, setFirstDropdown] = useState(
        props.activeTab == "respeitar" ||
        props.activeTab == "costurar" ||
        props.activeTab == "abrigar" ||
        props.activeTab == "afetar" ||
        props.activeTab == "confrontar" ||
        props.activeTab == "amar" ||
        props.activeTab == "ir" ||
        props.activeTab == "fotografar" ||
        props.activeTab == "proteger" ||
        props.activeTab == "respeitar" ||
        props.activeTab == "pertencer" ||
        props.activeTab == "cuidar" ||
        props.activeTab == "libertar" ||
        props.activeTab == "festejar" ||
        props.activeTab == "gestar" ||
        props.activeTab == "brincar" ||
        props.activeTab == "habitar"
    );
    const [secondDropdown, setSecondDropdown] = useState(
        props.activeTab == "linhaTempo" || props.activeTab == "tempoCasa"
    );

    return (
        <>
            <Row
                className="sidebar-row"
                style={{
                    height: "100vh",
                    justifyContent: "left",
                }}
            >
                {expanded && (
                    <Col
                        style={{
                            width: "15em",
                            height: "85vh",
                            overflow: "scroll",
                            marginTop: "3em",
                        }}
                    >
                        <Nav
                            className="col-md-12 d-md-block"
                            activeKey="/home"
                            //  onSelect={selectedKey => setActiveTab(selectedKey => selectedKey)}
                        >
                            {/*<Nav.Item>*/}
                            {/*    <Nav.Link*/}
                            {/*        className="ms-auto mt-4 special-font memory-nav-link"*/}
                            {/*        style={{*/}
                            {/*            color: props.activeTab == "casa" ? "#EE0000" : (ProjectColors.getInstance().isHighContrast
                                                ? ProjectColors.getInstance().White
                                                : ProjectColors.getInstance().Black),*/}
                            {/*        }}*/}
                            {/*        eventKey="casa"*/}
                            {/*        onClick={() => {*/}
                            {/*            navigate("/memory/main");*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        A CASA*/}
                            {/*    </Nav.Link>*/}
                            {/*</Nav.Item>*/}

                            <Nav.Item>
                                <Nav.Link
                                    className="ms-auto mt-4 special-font memory-nav-link dropdown"
                                    style={{
                                        color:
                                            firstDropdown ||
                                            props.activeTab == "respeitar" ||
                                            props.activeTab == "costurar" ||
                                            props.activeTab == "abrigar" ||
                                            props.activeTab == "afetar" ||
                                            props.activeTab == "confrontar" ||
                                            props.activeTab == "amar" ||
                                            props.activeTab == "ir" ||
                                            props.activeTab == "fotografar" ||
                                            props.activeTab == "proteger" ||
                                            props.activeTab == "respeitar" ||
                                            props.activeTab == "pertencer" ||
                                            props.activeTab == "libertar" ||
                                            props.activeTab == "festejar" ||
                                            props.activeTab == "brincar" ||
                                            props.activeTab == "cuidar" ||
                                            props.activeTab == "habitar" ||
                                            props.activeTab == "gestar" ||
                                            props.activeTab == "caixas"
                                                ? "#EE0000"
                                                : ProjectColors.getInstance().isHighContrast
                                                    ? ProjectColors.getInstance().White
                                                    : ProjectColors.getInstance().Black,
                                    }}
                                    onClick={() => {
                                        setFirstDropdown(!firstDropdown);
                                        if (props.activeTab != "caixas") {
                                            navigate("/memory/houses");
                                        }
                                    }}
                                >
                                    OS GESTOS
                                </Nav.Link>
                            </Nav.Item>

                            <div
                                className={firstDropdown ? "d-block" : "d-none"}
                                style={{marginLeft: 20}}
                            >
                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "amar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="amar"
                                        onClick={() => {
                                            navigate("/memory/amar");
                                        }}
                                    >
                                        AMAR
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "habitar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="habitar"
                                        onClick={() => {
                                            navigate("/memory/habitar");
                                        }}
                                    >
                                        HABITAR
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "libertar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="libertar"
                                        onClick={() => {
                                            navigate("/memory/libertar");
                                        }}
                                    >
                                        LIBERTAR
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "festejar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="festejar"
                                        onClick={() => {
                                            navigate("/memory/festejar");
                                        }}
                                    >
                                        FESTEJAR
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "proteger"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="proteger"
                                        onClick={() => {
                                            navigate("/memory/proteger");
                                        }}
                                    >
                                        PROTEGER
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "pertencer"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="pertencer"
                                        onClick={() => {
                                            navigate("/memory/pertencer");
                                        }}
                                    >
                                        PERTENCER
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "gestar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="gestar"
                                        onClick={() => {
                                            navigate("/memory/gestar");
                                        }}
                                    >
                                        GESTAR
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "ir"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="ir"
                                        onClick={() => {
                                            navigate("/memory/ir");
                                        }}
                                    >
                                        IR E VIR
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "fotografar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="fotografar"
                                        onClick={() => {
                                            navigate("/memory/fotografar");
                                        }}
                                    >
                                        FOTOGRAFAR
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "brincar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="brincar"
                                        onClick={() => {
                                            navigate("/memory/brincar");
                                        }}
                                    >
                                        BRINCAR
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "afetar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="afetar"
                                        onClick={() => {
                                            navigate("/memory/afetar");
                                        }}
                                    >
                                        AFETAR
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "cuidar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="cuidar"
                                        onClick={() => {
                                            navigate("/memory/cuidar");
                                        }}
                                    >
                                        CUIDAR
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "confrontar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="confrontar"
                                        onClick={() => {
                                            navigate("/memory/confrontar");
                                        }}
                                    >
                                        CONFRONTAR
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "respeitar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="respeitar"
                                        onClick={() => {
                                            navigate("/memory/respeitar");
                                        }}
                                    >
                                        RESPEITAR
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "costurar"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="costurar"
                                        onClick={() => {
                                            navigate("/memory/costurar");
                                        }}
                                    >
                                        COSTURAR
                                    </Nav.Link>
                                </Nav.Item>
                            </div>
                            <Nav.Item>
                                <Nav.Link
                                    className="ms-auto mt-2 special-font memory-nav-link"
                                    style={{
                                        color:
                                            props.activeTab == "mulheres"
                                                ? "#EE0000"
                                                : ProjectColors.getInstance().isHighContrast
                                                    ? ProjectColors.getInstance().White
                                                    : ProjectColors.getInstance().Black,
                                    }}
                                    eventKey="mulheres"
                                    onClick={() => {
                                        navigate("/memory/mulheres");
                                    }}
                                >
                                    AS MULHERES
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    className="ms-auto mt-2 special-font memory-nav-link"
                                    style={{
                                        color:
                                            secondDropdown ||
                                            props.activeTab == "linhaTempo" ||
                                            props.activeTab == "tempoCasa"
                                                ? "#EE0000"
                                                : ProjectColors.getInstance().isHighContrast
                                                    ? ProjectColors.getInstance().White
                                                    : ProjectColors.getInstance().Black,
                                    }}
                                    eventKey="festejar"
                                    onClick={() => {
                                        setSecondDropdown(!secondDropdown);
                                    }}
                                >
                                    CASA-MEMÓRIA
                                </Nav.Link>
                            </Nav.Item>

                            <div
                                className={secondDropdown ? "d-block" : "d-none"}
                                style={{marginLeft: 20}}
                            >
                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "linhaTempo"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="linhaTempo"
                                        onClick={() => {
                                            navigate("/memory/linhaTempo");
                                        }}
                                    >
                                        LINHA DO TEMPO
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        className="ms-auto mt-2 special-font memory-nav-link"
                                        style={{
                                            color:
                                                props.activeTab == "tempoCasa"
                                                    ? "#EE0000"
                                                    : ProjectColors.getInstance().isHighContrast
                                                        ? ProjectColors.getInstance().White
                                                        : ProjectColors.getInstance().Black,
                                        }}
                                        eventKey="tempoCasa"
                                        onClick={() => {
                                            navigate("/memory/tempoCasa");
                                        }}
                                    >
                                        O TEMPO NA CASA
                                    </Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                    </Col>
                )}
                {/*<Col style={{maxWidth: 0}}>*/}
                {/*    <div className="vr" style={{height: "100%"}}></div>*/}
                {/*</Col>*/}
                <Col
                    style={{
                        maxWidth: "1em",
                        marginRight: "1em",
                        marginTop: "1em",
                        backgroundColor: "transparent",
                    }}
                >
                    <Button
                        style={{
                            backgroundColor: "transparent",
                            border: "none",
                            padding: 0,
                        }}
                    >
                        <img
                            src={Arrow}
                            style={{
                                height: "2.2em",
                                width: "1.5em",
                                transform: `scaleX(${expanded ? -1 : 1})`,
                            }}
                            onClick={() => {
                                setExpanded((curr) => !curr);
                            }}
                            alt={
                                "Seta laranja apontando para expandir ou colapsar navegação lateral"
                            }
                        />
                    </Button>
                </Col>
            </Row>
        </>
    );
}
